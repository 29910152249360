// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React, {useEffect} from 'react'
import Seo from '../../src/components/seo'
import {SchemaLinksToAbsolute} from '../../src/helpers/SchemaLinksToAbsolute'
import { graphql } from 'gatsby'

import ServiceIntroSection from '../../src/components/page/ServiceIntroSection'
import BasicTextBlock from '../../src/components/page/BasicTextBlock'
import ServiceBarChartTextButton from '../../src/components/page/ServiceBarChartTextButton'
import ServiceTextImage from '../../src/components/page/ServiceTextImage'
import Services3Image from '../../src/components/page/Services3Image'
import ServiceCallToActionWImage from '../../src/components/page/ServiceCallToActionWImage'
import CaseStudyNumberedSlider from '../../src/components/page/CaseStudyNumberedSlider'
import BasicTestimonial from '../../src/components/page/BasicTestimonial'
import BasicBrandLogos from '../../src/components/page/BasicBrandLogos'
import BasicRelatedPosts from '../../src/components/page/BasicRelatedPosts'
import BasicCallToAction from '../../src/components/page/BasicCallToAction'
import BasicMoreServices from '../../src/components/page/BasicMoreServices'

const PageTemplate = pageProps => {
  let data = pageProps.data;
  const seoTitle = pageProps?.data?.wpPage?.seo?.title
  const seoDesc = pageProps?.data?.wpPage?.seo?.metaDesc
data = pageProps.data.wpPage
  const componentsArray = data.acf_pageBuilder.pageBuilder || []
  const components = componentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
      showBreadcrumbs: false,
    }
  })
  let colourSetter = {
    color1: pageProps.pageContext.color1, 
    color2: pageProps.pageContext.color2, 
    headerColor: 'black',
  }

  useEffect(() => {
    if(typeof window !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white');
    } 
  })

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const scroller = document.querySelector('#container-scroller')
      const anchors = Array.from(document.querySelectorAll('[href^="#"]'))
      const header = document.querySelector('#header')
      const hh = header.offsetHeight
      
      if(anchors.length > 0){
        anchors.map(anchor => {
          anchor.addEventListener('click', function(e){
            e.preventDefault()
            console.log('clicked')
            const id = anchor.hash;
            const yourElement = document.querySelector(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset - hh - 35;
            scroller.scrollTo({top: y, behavior: 'smooth'});
          })
        })
      }
    }
  })
  
  return (
    <div 
    className='outer-component-wrapper' 
    data-color1={colourSetter.color1} 
    data-color2={colourSetter.color2} 
    data-headerColor={colourSetter.headerColor} 
    data-slug={pageProps.pageContext.slug}
    data-abbreviated-title={pageProps?.pageContext?.abbreviatedTitle}
    data-long-title={pageProps?.pageContext?.longTitle}
    data-case-title={pageProps?.pageContext?.caseStudyTitle}
    >
    <Seo 
    title={seoTitle} 
    description={seoDesc} 
    date={pageProps.pageContext.date}
    slug={'/'+pageProps.pageContext.slug+'/'}
    robotsFollow={pageProps.data.wpPage.seo.metaRobotsNofollow}
    robotsIndex={pageProps.data.wpPage.seo.metaRobotsNoindex}
    canonicalUrl={pageProps.data.wpPage.seo.canonical}
    />
    {pageProps.pageContext.schema !== undefined ? 
      <SchemaLinksToAbsolute schemaRaw={pageProps.pageContext.schema} /> 
    : "" }
    {/* <div className={pageProps.pageContext.slug}> */}
      {components.map((component, index) => {

      if (component.name == 'ServiceIntroSection') {
        return <ServiceIntroSection {...component.data} key={index} />
      }
    

      if (component.name == 'BasicTextBlock') {
        return <BasicTextBlock {...component.data} key={index} />
      }
    

      if (component.name == 'ServiceBarChartTextButton') {
        return <ServiceBarChartTextButton {...component.data} key={index} />
      }
    

      if (component.name == 'ServiceTextImage') {
        return <ServiceTextImage {...component.data} key={index} />
      }
    

      if (component.name == 'Services3Image') {
        return <Services3Image {...component.data} key={index} />
      }
    

      if (component.name == 'ServiceCallToActionWImage') {
        return <ServiceCallToActionWImage {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyNumberedSlider') {
        return <CaseStudyNumberedSlider {...component.data} key={index} />
      }
    

      if (component.name == 'BasicTestimonial') {
        return <BasicTestimonial {...component.data} key={index} />
      }
    

      if (component.name == 'BasicBrandLogos') {
        return <BasicBrandLogos {...component.data} key={index} />
      }
    

      if (component.name == 'BasicRelatedPosts') {
        return <BasicRelatedPosts {...component.data} key={index} />
      }
    

      if (component.name == 'BasicCallToAction') {
        return <BasicCallToAction {...component.data} key={index} />
      }
    

      if (component.name == 'BasicMoreServices') {
        return <BasicMoreServices {...component.data} key={index} />
      }
    
        return <div>Error: The component {component.name} was not found</div>
      })}
    </div>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery4131($id: String!) {
        wpPage(id: {eq: $id}) {
          title
          seo{
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
          }
          
    acf_pageBuilder {
      pageBuilder {
        __typename
         
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicAccordion {
         
        accordion {
            heading
            text
        }
        sectionTitle {
            headingType
            titleText
        }
        removeBottomMargin
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBigBoldStatementBlockQuote {
        
   
            fieldGroupName
            removeBottomMargin
            statement
            textColour
   
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBrandLogos {
        
      
        label
        removeBottomMargin
        removeGrayscale
        brandLogosGatsby {
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicCallToAction {
        
      
        fieldGroupName
        removeBottomMargin
        callToActionGatsby {
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
          ctaHeading
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthImage {
        
 
        caption
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        notFullWidth
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthVideo {
        
      
        removeBottomMargin
        videoEmbedCode
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicGallery {
        
      
        fieldGroupName
        removeBottomMargin
        gallery {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              relativePath
            }
          }
        }
       
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicImageGrid {
        
 
       imageGrid{
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
       }
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicMoreServices {
        
      
        removeBottomMargin
        pickServicesGatsby {
          service1 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service2 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service3 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service4 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service5 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicRelatedPosts {
        
      
        removeBottomMargin
        postPicker {
          buttonText
          headline
          label
          rolloverText
          pickPost {
            ... on WpBlog {
              id
              link
              title
              acf_customFeaturedImage {
                customFeaturedImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
            ... on WpCaseStudy {
              id
              link
              title
              acf_caseStudyFields {
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
          }
          rolloverText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTable {
        
      
        fieldGroupName
        removeBottomMargin
        tableRow {
          isHeaderRow
          highlightRow
          tableColumns {
            tableCell
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonial {
        
      
        fieldGroupName
        personPositionCompany
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        personPhotoOffsets {
          offsetX
          offsetY
        }
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonialSlider {
        
        fieldGroupName
        testimonialSlider{
          personPositionCompany
          personPhoto {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          personPhotoOffsets {
            offsetX
            offsetY
          }
          quotation
        }
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock {
        
      
        addDividerLine
        removeBottomMargin
        text

    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock2col {
        
      
        addDividerLine
        removeBottomMargin
        text
        text2
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatisticArrow {
        
      
      removeBottomMargin
      animatedStatisticWArrow {
        fieldGroupName
        label
        number
        symbol
      }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatistics6BackgroundImage {
        
        
          fieldGroupName
          heading
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          removeBottomMargin
          text
          statistics {
            addColourBackground
            fieldGroupName
            label
            number
            symbol
          }
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyHeroImageBlock {
        
      
        backgroundImageFocalPoint
        backgroundImageOpacity
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        heading
        logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        logoWidth
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyNumberedSlider {
        
      
        removeBottomMargin
        slider {
          imageType
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          text
        }
        sliderLabel
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyParallaxImageText {
        
      
        backgroundColour1
        backgroundColour2
        removeBottomMargin
        text
        parallaxImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotation {
        
      
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotationSlider {
        
      quotations {
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
      }
      removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyTextStatBackgroundImage {
        
      
        removeBottomMargin
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        text
        statisticGatsby {
          label
          number
          symbol
        }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceBarChartTextButton {
        
      
        fieldGroupName
        removeBottomMargin
        barChart {
          chartTitle
          dataValues {
            dataValue
            xDataValue
          }
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
        }
        barChart2datasets {
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
        }
        singleOrMultipleDatasets
        text
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              id
              link
            }
          }
          buttonText
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceCallToActionWImage {
        
      
        backgroundColor1
        backgroundColor2
        heading
        headingTag
        removeBottomMargin
        text
        buttonGatsby {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
              title
            }
          }
          buttonText
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceIntroSection {
        
        addCountdownTimer
        countdownFrom
        heading
        removeBottomMargin
        scrollingBackgroundText
        agencyPrefixText
        source
        text
        ctaButton{
            target
              title
              url
        }
        animatedIconVideoUrl
        specifyParentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
        specifyGrandparentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceLineChartTextButton {
        
      
        removeBottomMargin
        lineChart {
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xLabelCustomTooltip
          xUnitLabel
          xUnitLabelCustomTooltip
          yLabelCustomTooltip
          yLabel
          yUnitLabel
          yUnitLabelCustomTooltip
        }
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
            }
          }
          buttonText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextImage {
        
        useCustomBackgroundColour
        backgroundColour1
        backgroundColour2
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        imageFocalPoint
        imageToFillSpace
        label
        padding {
          paddingBottom
          paddingLeft
          paddingRight
          paddingTop
        }
        removeBottomMargin
        text
        textimagebutton{
          textimagebuttontext
          textimagebuttonlink
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextVideo {
        
      
        backgroundColour1
        backgroundColour2
        imageToFillSpace
        video
        removeBottomMargin
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_Services3Image {
        
      
        removeBottomMargin
        imagesX3 {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesServicesGridVideoTitleText {
        
      
        removeBottomMargin
        servicesServicesGridVideoTitleText {
          videoUrl
          text
          title
          linkToService {
            ... on WpService {
              uri
            }
          }
        }
        servicesGridHeading
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesStaffBlock {
        
      
        servicesStaffBlockHeading
        servicesStaffBlockIntroText
        servicesStaffRepeater{
            text
            staffMember{
                ... on WpTeamMember{
                    title
                    slug
                    acf_staff_member{
                        colour
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_VideoTranscriptionBlock {
        


        text

    
      }
    
      }
    }
  
        }
      }
    `
  